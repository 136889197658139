import React from "react";
import { transformVar } from "@quarkly/atomize";
import { createGlobalStyle } from "styled-components";

const pageStyles = {
    "404": {
        "display": "flex",
        "font": "normal 800 72px/1.2 --fontFamily-sans",
        "letter-spacing": ""
    },
    "index": {
        "display": "block",
        "font": "normal 800 72px/1.2 --fontFamily-sans",
        "letter-spacing": ""
    },
    "aanbod": {
        "display": "block",
        "font": "normal 800 72px/1.2 --fontFamily-sans",
        "letter-spacing": ""
    },
    "aanbod/analyse": {
        "display": "block",
        "font": "normal 800 72px/1.2 --fontFamily-sans",
        "letter-spacing": ""
    },
    "aanbod/ontwikkeling": {
        "display": "block",
        "font": "normal 800 72px/1.2 --fontFamily-sans",
        "letter-spacing": ""
    },
    "aanbod/ondersteuning": {
        "display": "block",
        "font": "normal 800 72px/1.2 --fontFamily-sans",
        "letter-spacing": ""
    },
    "aanpak1": {
        "display": "block",
        "font": "normal 800 72px/1.2 --fontFamily-sans",
        "letter-spacing": ""
    },
    "aanpak": {
        "display": "block",
        "font": "normal 800 72px/1.2 --fontFamily-sans",
        "letter-spacing": ""
    },
    "overdimple": {
        "display": "block",
        "font": "normal 800 72px/1.2 --fontFamily-sans",
        "letter-spacing": ""
    },
    "contact": {
        "display": "block",
        "font": "normal 800 72px/1.2 --fontFamily-sans",
        "letter-spacing": ""
    }
};

const PageStyles = createGlobalStyle`
    body {
        ${({ styles }) =>
            Object.entries(styles || {}).map(
                ([prop, value]) => `${prop}: ${transformVar(prop, value)};`
            )}
    }
`;
export const GlobalQuarklyPageStyles = ({ pageUrl }) => <PageStyles styles={pageStyles[pageUrl]} />
